import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as ArchiveIcon } from '@icons/wolfkit-light/archivebox-light.svg';
import { ReactComponent as SubscribeIcon } from '@icons/custom/add-copy-light.svg';
import { ReactComponent as CopyIcon } from '@icons/custom/copy-light.svg';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import IconButton from '@shared/ui/buttons/Button/IconButton';
const Config = {
    edit: {
        variant: 'filled',
        IconComponent: EditIcon,
    },
    view: {
        variant: 'tinted',
        IconComponent: CopyIcon,
    },
    subscribe: {
        variant: 'filled',
        IconComponent: SubscribeIcon,
    },
    view_archive: {
        variant: 'filled',
        IconComponent: ArchiveIcon,
    },
};
const PortfolioPrimaryButton = ({ portfolio, actionType, disabled = false, onAction = () => { }, }) => {
    const onClickCapture = (event) => {
        event.stopPropagation();
        onAction(event, actionType, portfolio.id);
    };
    if (actionType === 'none' || actionType === 'reconnect') {
        return null;
    }
    return (_jsx(IconButton, { size: 'medium', color: 'primary', variant: Config[actionType].variant, IconComponent: Config[actionType].IconComponent, onClickCapture: onClickCapture, disabled: disabled }));
};
export default PortfolioPrimaryButton;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { AreaClosed, LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { max, extent } from '@visx/vendor/d3-array';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { ContainerColumn } from '@src/components/styled';
import MockData from '../../mocks.json';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: '13px',
}));
const graphData = MockData
    .sort((a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return aDate - bDate;
})
    .slice(0, 125);
const getDate = (d) => new Date(d.date);
const getCurrencyValue = (d) => Number(d.currency);
const PortfolioGraph = ({ width, height, colorVariant = 'positive', }) => {
    const theme = useTheme();
    const innerWidth = width;
    const innerHeight = height;
    const data = graphData;
    const dateScale = useMemo(() => scaleTime({
        range: [0, innerWidth],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        domain: extent(data, getDate),
    }), [data, innerWidth]);
    const currencyValueScale = useMemo(() => scaleLinear({
        range: [innerHeight, 0],
        domain: [0, max(data, getCurrencyValue) || 0],
        nice: true,
    }), [data, innerHeight]);
    const strokeColor = () => (colorVariant === 'positive' ?
        theme.customColors.graph.portfolio.strokePositive :
        theme.customColors.graph.portfolio.strokeNegative);
    const fillGradientId = () => (colorVariant === 'positive' ?
        'area-gradient-positive' :
        'area-gradient-negative');
    return (_jsxs(Container, { children: [_jsxs("svg", { width: width, height: height, children: [_jsx(LinearGradient, { id: 'area-gradient-positive', from: theme.customColors.graph.portfolio.fillPositive, to: theme.customColors.graph.portfolio.fillPositive, toOpacity: 0 }), _jsx(LinearGradient, { id: 'area-gradient-negative', from: theme.customColors.graph.portfolio.fillNegative, to: theme.customColors.graph.portfolio.fillNegative, toOpacity: 0 }), _jsx(LinePath, { curve: curveMonotoneX, data: data, x: d => { var _a; return (_a = dateScale(getDate(d))) !== null && _a !== void 0 ? _a : 0; }, y: d => { var _a; return (_a = currencyValueScale(getCurrencyValue(d))) !== null && _a !== void 0 ? _a : 0; }, stroke: strokeColor(), strokeWidth: 1.5, shapeRendering: 'geometricPrecision' }), _jsx(AreaClosed, { data: data, x: d => { var _a; return (_a = dateScale(getDate(d))) !== null && _a !== void 0 ? _a : 0; }, y: d => { var _a; return (_a = currencyValueScale(getCurrencyValue(d))) !== null && _a !== void 0 ? _a : 0; }, yScale: currencyValueScale, fill: `url(#${fillGradientId()})`, curve: curveMonotoneX })] }), _jsx("svg", { width: width, height: '2', viewBox: '0 0 380 2', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: _jsx("path", { d: 'M0.505319 0.60577H379.495', stroke: '#687076', strokeLinecap: 'round', strokeLinejoin: 'round', strokeDasharray: '0.3 6' }) })] }));
};
export default PortfolioGraph;
